@import url('https://fonts.googleapis.com/css2?family=Handlee&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300&family=Gowun+Dodum&display=swap');



.App {
  text-align: center;
  background-color: #071C27;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}


.flexLR {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.flexColumn {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  justify-content: start;
  margin-right: 8rem;

}

.name {
  font-family: 'Fredoka';
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 600;
  margin-top: -2rem;
  color: #fabf88;
  text-align: start;
}

.descMain {
  font-family: 'Gowun Dodum';
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 200;
  color: #FFFFFF;
  text-align: start;
  margin-top: 0.5rem;
  text-decoration: none;
  transition: transform 0.3s ease;
}

.descMain:hover {
  color: #fabf88;
  transform: translateX(4px)
}

.profile-image {
  width: 45rem;
  height: auto;
  margin-top: 2.5rem;
}

.socials {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: -45rem;
  margin-top: -1rem;
}

.social-image {
  margin-left: 2rem;
  color: #555555;
  font-size: 50px;
  transition: color 0.3s ease;
}

.social-image:hover {
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  .flexLR {
    flex-direction: column;
    align-items: center;
  }

  .flexColumn {
    margin-right: 0;
  }

  .profile-image {
    width: 80%;
    max-width: 300px;
    margin-top: 2rem;
  }

  .socials {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 2rem 0;
  }

  .social-image {
    margin: 0 1rem;
    transition: color 0.3s ease;
  }

  .social-image:hover {
    color: #ffffff;
  }
}

/* Projects */

.proj {
  background: #071C27;
  min-height: 100vh;
  flex: shrink 0;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 3000px;
  width: 100%;
  padding-top: 2rem;
}

.y2023 {
  font-family: 'Fredoka';
  font-style: normal;
  font-weight: 500;
  background: #fabf88;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 4.4rem;
  margin-top: -5rem;
  margin-bottom: -4.3rem;
  margin-left: -33rem;
}

.projLayout {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.infoLayout {
  display: grid;
  grid-template-columns: auto auto auto auto;
  align-items: center;
  margin: 0 auto;
  text-decoration: none;
  pointer-events: none;
}

.projBar2024 {
  width: 16px;
  height: 433px;
  opacity: 1;
  border-radius: 18px;
  background: #fabf88;
  text-decoration: none;
  margin-bottom: 2rem;
  margin-left: 3rem;
}

.projBar2023 {
  width: 16px;
  height: 803px;
  opacity: 1;
  border-radius: 18px;
  background: #fabf88;
  text-decoration: none;
  margin-bottom: 2rem;
  margin-left: 3rem;
}


.projDesc {
  text-align: center;
  font-family: 'Gowun Dodum';
  font-size: 1.8rem;
  font-weight: 300;
  color: #FFFFFF;
  padding: 20px 0;
  text-decoration: None;
  pointer-events: auto;
  width: 300px;
  word-wrap: break-word;
  margin-left: 2rem;
}

.projDesc:hover {
  color: #fabf88;
}


.projDate {
  text-align: center;
  font-family: 'Fredoka';
  font-size: 1.8rem;
  font-weight: 600;
  color: #FFFFFF;
  pointer-events: none;
  margin-right: 0.4rem;
  margin-left: 7rem;
}

.triangle {
  width: 0;
  height: 0;
  border-top: 28px solid transparent;
  border-bottom: 28px solid transparent;
  border-left: 40px solid #A9B6BE;
  margin-left: 6rem;
  margin-right: 1.5rem;
  pointer-events: none;
}

.inner-triangle {
  position: relative;
  top: -20px;
  left: -36px;
  width: 0;
  height: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
  border-left: 28px solid #D9D9D9;
  pointer-events: none;
}


/* Blogs */
.blogs {
  background: #071C27;
  min-height: 100vh;
  flex: shrink 0;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 3000px;
  width: 100%;
  padding-top: 2rem;
}

.blogTitle {
  font-family: 'Fredoka';
  font-style: normal;
  font-weight: 500;
  background: #fabf88;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 4.4rem;
  margin-top: -5rem;
  margin-bottom: -4.3rem;
}

.subtext {
  text-align: center;
  font-family: 'Handlee';
  font-size: 2rem;
  font-style: normal;
  font-weight: 300;
  color: #2F4550;
  padding-top: 0.01rem;
}

.blogLayout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-decoration: None;
  pointer-events: None;
}

.blogDesc {
  text-align: center;
  font-family: 'Gowun Dodum';
  font-size: 1.2rem;
  font-weight: 300;
  color: #FFFFFF;
  pointer-events: None;
  width: 300px;
  word-wrap: break-word;
  margin-top: -1.5rem;
}

.blogName {
  text-align: center;
  font-family: 'Gowun Dodum';
  font-size: 2.3rem;
  font-weight: 500;
  color: #FFFFFF;
  text-decoration: None;
  pointer-events: None;
  width: 300px;
  word-wrap: break-word;
  margin-top: -1rem;
}

.blogImg {
  height: auto;
  margin-top: 2.5rem;
  pointer-events: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: .3s ease;
}


.blogImg:hover {
  opacity: 0.8;

}

/* Nav */

.topNav {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: -2rem;
  margin-bottom: 6rem;
  pointer-events: None;
  justify-content: center;
}

.navHeader {
  text-align: center;
  font-family: 'Gowun Dodum';
  font-size: 1.5rem;
  font-weight: 300;
  color: #FFFFFF;
  pointer-events: auto;
  text-decoration: None;
  margin-left: 2.7rem;
}

.navHeader:hover {
  color: #fabf88;
}


.homeIcon {
  color: #555555;
  font-size: 55px;
  margin-left: 2rem;
  pointer-events: auto;
}

.homeIcon:hover {
  color: #ffffff;
}

/* about */

.about {
  background: #071C27;
  min-height: 100vh;
  flex: shrink 0;
  text-align: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 2000px;
  width: 100%;
  padding-top: 2rem;
}

.aboutImg {
  height: auto;
  margin-top: 2rem;
  pointer-events: auto;
  width: 25%;
}

.yap {
  font-family: 'Gowun Dodum';
  font-size: 1.4rem;
  font-weight: 300;
  color: #FFFFFF;
  margin-top: 3rem;
  justify-content: center;
  align-items: center;
  max-width: 40%;
  margin: 0 auto;
}